import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "drawer-left" }
const _hoisted_3 = { class: "bullet-list" }
const _hoisted_4 = { class: "bullet-list" }
const _hoisted_5 = { class: "bullet-list" }
const _hoisted_6 = { class: "bullet-list" }
const _hoisted_7 = { class: "bullet-list" }
const _hoisted_8 = { class: "bullet-list" }
const _hoisted_9 = { class: "bullet-list" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_el_image = _resolveComponent("el-image")
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")
  const _component_el_carousel = _resolveComponent("el-carousel")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_CircleCheckFilled = _resolveComponent("CircleCheckFilled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight
    }, null, 8, ["windowWidth", "windowHeight"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.windowWidth >= 982)
        ? (_openBlock(), _createBlock(_component_el_carousel, {
            key: 0,
            height: "60vh",
            "indicator-position": "none",
            arrow: "never",
            interval: 10000
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c2.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c3.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c1.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c4.webp" })
                ]),
                _: 1
              }),
              _withDirectives(_createElementVNode("div", _hoisted_2, _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "welcome-desktop" }, " Für ein modernes ", -1),
                _createElementVNode("div", { class: "welcome-large" }, " Gesundheitsamt ", -1),
                _createElementVNode("div", { class: "welcome-small" }, [
                  _createElementVNode("p", null, "Die zunehmende Personalknappheit erfordert die Schonung eigener Ressourcen."),
                  _createElementVNode("p", null, "Wir bieten deutschen Gesundheitsämtern eine sichere Plattform zur online Durchführung der Heilpraktiker-Kenntnisüberprüfungen.")
                ], -1)
              ]), 512), [
                [_vShow, _ctx.showDrawerLeft && _ctx.windowWidth >= 982]
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.windowWidth < 982)
        ? (_openBlock(), _createBlock(_component_el_carousel, {
            key: 1,
            height: "50vw",
            "indicator-position": "none",
            arrow: "never",
            interval: 10000
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c2.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c3.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c1.webp" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_carousel_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, { src: "/img/c4.webp" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _cache[26] || (_cache[26] = _createElementVNode("div", { class: "title" }, [
        _createElementVNode("h2", { class: "blue0" }, "Steigerung der digitalen Reife"),
        _createElementVNode("p", null, " Moderne Gesundheitsämter digitalisieren ihre Prozesse in zunehmendem Maße. Auch die Abnahme der Heilpraktiker-Kenntnisüberprüfung ist nun online möglich. Kontaktieren Sie uns für ein Kennenlernangebot unserer Plattform MC.health. ")
      ], -1)),
      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "single-block-header" }, " Unterstützte Web-Browser ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, { src: "/img/chrome.png" })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Google Chrome ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, { src: "/img/edge.png" })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Microsoft Edge ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, { src: "/img/safari.png" })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Apple Safari ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, { src: "/img/firefox.png" })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Mozilla Firefox ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[28] || (_cache[28] = _createElementVNode("div", { class: "single-block-header" }, " Erforderliche Ausstattung ", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Prüfungsraum mit WLAN, bspw. Besprechungsraum / Schulungsraum ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Aufsichtspersonal mit geringer Qualifikation ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[29] || (_cache[29] = _createElementVNode("div", { class: "single-block-header" }, " Unsere Vorteile ", -1)),
      _cache[30] || (_cache[30] = _createElementVNode("div", { class: "single-block-subheader" }, " Sicherheit ", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Keine Installation auf den Servern der Verwaltung erforderlich ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Separates Hosting für jedes einzelne Gesundheitsamt ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Sicheres Rollenkonzept für Nutzer*innen ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "single-block-subheader" }, " Datenschutz ", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Keine Speicherung personenbezogener oder gesundheitsbezogener Daten ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" Identifikation der Prüfungsteilnehmer*innen ausschließlich im Gesundheitsamt ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Transparente Information über gespeicherte Daten und Löschfristen ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Unterstützung bei der Datenschutzfolgenabschätzung ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Server & Hosting in Deutschland ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "single-block-subheader" }, " Effizienz ", -1)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" Kosten pro Prüfung, geringe laufende Kosten ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Keine Raummiete erforderlich ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Kein Zeitaufwand zur Prüfungserstellung und -auswertung ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode(" Geringer Schulungsaufwand für Mitarbeitende ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode(" Berücksichtigung von Nachteilsausgleichen (Prüfungsverlängerung) ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode(" Einstellungen zur besseren Lesbarkeit (Größe / Kontrast) ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode(" Steigerung der Digitalen Reife nach dem Reifegradmodell 1.1 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "single-block-subheader" }, " Medizinische Kompetenz ", -1)),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode(" Prüfungsfragen von ärztlichem Personal mit Erfahrung im öffentlichen Gesundheitsdienst ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode(" Bereitstellung von Referenzen für die Bearbeitung in Widerspruchsverfahren ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[34] || (_cache[34] = _createElementVNode("div", { class: "single-block-subheader" }, " Nachhaltigkeit ", -1)),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode(" Papierfreies Arbeiten ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "bullet-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 0.5,
              class: "bullet-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "48px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleCheckFilled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 20,
              class: "bullet-text"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode(" Prüfungsteilnahme vor Ort ohne Fernreisen ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_Footer, {
      windowWidth: _ctx.windowWidth,
      windowHeight: _ctx.windowHeight
    }, null, 8, ["windowWidth", "windowHeight"])
  ], 64))
}